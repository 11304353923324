import React, { useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Text, Group, Badge, Box, Title, Button, Flex } from '@mantine/core';
import { getCurrencySymbol } from 'utils/currencySymbols';
import { useGetDealsQuery } from 'services/deals/deals.service';
import NotFound from 'pages/error/404/components/NotFound';
import CompanyHeader from 'components/Common/CompanyHeader';
import { useSelector, useDispatch } from 'react-redux';
import { updateInvestment } from 'components/Auth0/slices/auth0Slice';
import { PATH_LOGIN, PATH_SETTINGS_BASIC } from 'shared/constants';
import { setLocalStorage } from 'utils/browserUtil';
import { useAppSelector } from 'store/hooks';
import { selectShouldConfirm } from 'pages/settings/SettingsSlice';
import { trackEventsOnClick } from 'analytics';
import { useRegisterInterest } from 'hooks/useRegisterInterest';
import InvestmentForm from './InvestmentForm';
import DealsPageSkeleton from './DealsPageSkeleton';

const CompanyInfo: React.FC<{ data: any }> = ({ data }) => (
  <Box className="company-info">
    <CompanyHeader logoUrl={data.logo_url} name={data.company_name} summary={data.company_summary} size="lg" />
    <Group spacing={6} my="lg" className="company-profile">
      {data.tags.map((tag: any) => (
        <Badge key={tag} radius="sm">
          {tag}
        </Badge>
      ))}
    </Group>
  </Box>
);

const CompanyDetails: React.FC<{ data: any }> = ({ data }) => (
  <Box className="company-details">
    <Title order={3} weight={500}>
      Company
    </Title>
    <Text fz="md" mt={20} color="var(--mantine-color-dark-7)">
      {data.company_info}
    </Text>
  </Box>
);

const InvestMentMemo: React.FC<{ link: string }> = ({ link }) => (
  <Box className="company-investment-memo">
    <Title order={3} weight={500} mt={30}>
      Investment Memo
    </Title>
    <Button
      data-event="investment_memo"
      component="a"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      variant="filled"
      fz="md"
      mt={20}
      onClick={trackEventsOnClick}
    >
      View investment Memo
    </Button>
  </Box>
);

const DealsComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dealId = useParams<{ dealId: string }>().dealId ?? '';
  const { data, error, isLoading } = useGetDealsQuery(dealId);
  const user = useSelector((state: any) => state.auth0.user);
  const { registerInterest } = useRegisterInterest(dealId);
  const shouldConfirm = useAppSelector(selectShouldConfirm);

  const handleInvestClick = useCallback(
    (investmentValue: number) => {
      if (!user) {
        navigate(PATH_LOGIN); // Redirect to login
      } else if (shouldConfirm) {
        // Show verification prompt
        navigate(PATH_SETTINGS_BASIC);
      } else {
        // Proceed with investment
        setLocalStorage(`${dealId}-investment`, investmentValue);
        dispatch(updateInvestment({ dealId: dealId || '', investmentValue }));
        navigate(`/${dealId}/invest`);
      }
    },
    [dealId, dispatch, navigate, shouldConfirm, user]
  );

  const currency = getCurrencySymbol(data?.currency || 'USD');

  const investHandler = useCallback(
    (investmentValue: number) => {
      if (data?.can_invest) {
        handleInvestClick(investmentValue);
      } else {
        registerInterest(investmentValue);
      }
    },
    [data?.can_invest, handleInvestClick, registerInterest]
  );

  if (isLoading) {
    return <DealsPageSkeleton />;
  }

  if (error || !data) {
    return <NotFound />;
  }

  return (
    <Flex direction={{ base: 'column', md: 'row' }} gap="lg">
      <Box sx={{ flex: 2 }}>
        <CompanyInfo data={data} />
        <CompanyDetails data={data} />
        <InvestMentMemo link={data.investment_memo_document_url || ''} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <InvestmentForm
          data={data}
          currency={currency}
          isUserVerified={!shouldConfirm}
          user={user}
          onInvestClick={investHandler}
        />
      </Box>
    </Flex>
  );
};

export default DealsComponent;
