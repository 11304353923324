import { useMemo } from 'react';
import moment from 'moment';

type DealStatusText = 'ANNOUNCED' | 'LIVE' | 'CLOSED';

interface DealStatus {
  hasStarted: boolean;
  hasEnded: boolean;
  dealStatus: DealStatusText;
  isDisabled: boolean;
}

export const useDealStatus = ({ startDate, endDate }: { startDate: string; endDate: string }): DealStatus =>
  useMemo(() => {
    const dealStart = moment.parseZone(startDate).utc();
    const dealEnd = moment.parseZone(endDate).utc().endOf('day');
    const now = moment.utc();

    const secondsLeftToStart = dealStart.diff(now, 'second');
    const secondsLeftToEnd = dealEnd.diff(now, 'second');

    const hasStarted = secondsLeftToStart < 0;
    const hasEnded = secondsLeftToEnd < 0;

    let dealStatus: DealStatusText = 'ANNOUNCED';
    if (hasEnded) {
      dealStatus = 'CLOSED';
    } else if (hasStarted) {
      dealStatus = 'LIVE';
    }

    const isDisabled = dealStatus === 'CLOSED';

    return { hasStarted, hasEnded, dealStatus, isDisabled };
  }, [startDate, endDate]);
