import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Text, Group, Stack, Title, Button, Alert, Modal, Textarea } from '@mantine/core';
import DetailsContainer from 'components/Common/DetailsContainer/DetailsContainer';
import { formatDate } from 'utils/datetime';
import { formatNumberShort } from 'utils/currencySymbols';
import { PATH_ROOT } from 'shared/constants';
import { useAuth0 } from '@auth0/auth0-react';
import backendConfig from 'config/backend.config';
import { toast } from 'react-toastify';
import { fetchData } from 'utils/apiUtils';
import { ProductAnalytics, PaEvents } from 'analytics';
import InvestmentInput from 'components/Common/InvestmentInput';
import { useDealStatus } from 'hooks/useDealStatus';
import { dealsFields } from './utils';

const InvestmentForm: React.FC<{
  data: any;
  currency: string;
  isUserVerified: boolean;
  user: any;
  onInvestClick: (value: number) => void;
}> = ({ data, currency, isUserVerified, user, onInvestClick }) => {
  const { dealId } = useParams<{ dealId: string }>();
  const [investmentValue, setInvestmentValue] = useState<number | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isPassed, setPassedMessage] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { start_date: startDate, end_date: endDate } = data;

  const { isDisabled } = useDealStatus({ startDate, endDate });

  const passDeal = async () => {
    try {
      const token = await getAccessTokenSilently();

      ProductAnalytics.trackEvent(PaEvents.PASS_DEAL, {
        deal_id: dealId,
        reason: message,
      });

      await fetchData({
        url: `${backendConfig.baseUrl}v1/subscriptions`,
        method: 'PUT',
        token,
        data: { reason: message, deal_id: dealId, preference: 'OPT_OUT' },
      });
      toast.success('Thanks for your feedback');
      navigate(PATH_ROOT);
    } catch (error) {
      console.error('Failed to pass the deal:', error);
    } finally {
      setPassedMessage(false); // Close the modal regardless of success or failure
      setMessage(''); // Clear the message regardless of success or failure
    }
  };

  const handleInvestButtonClick = () => {
    if ((investmentValue === undefined || investmentValue < data.min_validation) && user && isUserVerified) {
      const minInvest = formatNumberShort(data.min_investment);
      setMessage(`Minimum investment amount is ${currency}${minInvest}`);
    } else {
      setMessage(undefined);
      onInvestClick(investmentValue || 0);
    }
  };

  const minInvest = formatNumberShort(data.min_investment);

  return (
    <>
      <Card shadow="lg">
        <Stack spacing="sm" mt="sm">
          <Title order={4} weight={500}>
            Invest now
          </Title>
          <Text color="dimmed" size="sm">
            Preferred min. investment <b>${minInvest}</b> by {formatDate(data.end_date, 'date')}
          </Text>
          <InvestmentInput
            currency={currency}
            minInvestment={data.min_investment}
            investmentValue={investmentValue}
            setInvestmentValue={(value) => setInvestmentValue(value as number)}
            isDisabled={isDisabled}
          />
          {message && !isPassed && <Alert>{message}</Alert>}
        </Stack>
        <Group grow mt="sm">
          <Button uppercase onClick={handleInvestButtonClick} disabled={isDisabled}>
            {isUserVerified ? 'Invest' : 'Verify Identity to Invest'}
          </Button>
          <Button
            uppercase
            variant="outline"
            disabled={isDisabled || !isUserVerified || !user}
            onClick={() => {
              setMessage('');
              setPassedMessage(true);
            }}
          >
            Pass
          </Button>
        </Group>
        <DetailsContainer fields={dealsFields} data={data} currencyCode={data.currency} />
      </Card>
      <Modal opened={isPassed} onClose={() => setPassedMessage(false)} title="Why would you like to pass the deal?">
        <Stack>
          <Textarea
            label="Help us understand your investment preferences by sharing your reasons for passing"
            value={message}
            minRows={5}
            onChange={(event) => setMessage(event.currentTarget.value)}
          />
          <Button fullWidth onClick={passDeal}>
            Submit
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default InvestmentForm;
