import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfirmInterestMutation } from 'services/invest/invest.service';

export const useRegisterInterest = (dealId: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const [confirmInterest, { isLoading, isSuccess, isError }] = useConfirmInterestMutation();

  const registerInterest = useCallback(
    async (amount: number) => {
      if (isLoading || isSuccess) return;
      try {
        const accessToken = await getAccessTokenSilently();
        await confirmInterest({
          dealId,
          amount,
          currency: 'USD',
          accessToken,
        }).unwrap();

        toast.success('Thanks for your commitment, we’ll get back with next steps shortly.');
      } catch (error) {
        toast.error('Something went wrong. Please try again later.');
      }
    },
    [confirmInterest, dealId, getAccessTokenSilently, isLoading, isSuccess]
  );

  return { registerInterest, isLoading, isSuccess, isError };
};
