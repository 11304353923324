import React from 'react';
import { Text } from '@mantine/core';

const data = [
  {
    key: 'Account A/c Number',
    value: '409001935968',
  },
  {
    key: 'Account A/c Name',
    value: 'TOKENFOLIO (BVI) LIMITED',
  },
  {
    key: 'Beneficiary Bank Name',
    value: 'RBL Bank Ltd – IFSC Banking Unit, GIFT City, India',
  },
  {
    key: 'NOSTRO Bank SWIFT Code/BIC',
    value: 'RATNINB2',
  },
  {
    key: 'Intermediary / Correspondent / Nostro Bank',
    value: 'WELLS FARGO BANK, NEW YORK',
  },
  {
    key: 'Correspondent bank SWIFT / Local Clearing IFSC Code',
    value: 'PNBPUS3NNYC',
  },
  {
    key: 'Correspondent Bank A/c No',
    value: '2000294891609',
  },
  {
    key: 'FED Routing No.',
    value: '026005092',
  },
  {
    key: 'Chips ABA No.',
    value: '0509',
  },
];

const BankDetailsDisplay = () => (
  <div
    style={{
      border: '2px dashed grey',
      fontSize: 13,
      color: '#333',
    }}
  >
    {data.map((detail, index) => (
      <div
        key={detail.key}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '5px 10px',
          backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
        }}
      >
        <span style={{ fontWeight: 'bold' }}>{detail.key}</span>
        <span>{detail.value}</span>
      </div>
    ))}
  </div>
);

export default BankDetailsDisplay;
