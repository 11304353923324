import React from 'react';
import { Avatar, Badge, Card, Group, Image, Text, Title, useMantineTheme, Box, Tooltip } from '@mantine/core';
import './CompanyCard.scss';
import { CompanyOverview } from 'models/company/company.model';
import { CompanyTag } from 'models/company/company.model.enums';
import { useDealStatus } from 'hooks/useDealStatus';
import { Link } from 'react-router-dom';

type Props = Pick<
  CompanyOverview,
  | 'id'
  | 'bannerUrl'
  | 'location'
  | 'logoUrl'
  | 'name'
  | 'summary'
  | 'tags'
  | 'dealEnd'
  | 'dealStart'
  | 'external'
  | 'externalLink'
  | 'external_platform'
>;

const CompanyCard: React.FC<Props> = ({
  id,
  bannerUrl,
  location,
  logoUrl,
  name,
  summary,
  tags,
  dealEnd,
  dealStart,
  external,
  externalLink,
  external_platform,
}) => {
  const theme = useMantineTheme();

  const { dealStatus, isDisabled } = useDealStatus({ startDate: dealStart, endDate: dealEnd });

  // Define badge colors based on deal status
  const statusColorMap = {
    LIVE: theme.colors.green[6],
    CLOSED: theme.colors.red[6],
    ANNOUNCED: theme.colors.gray[6],
  };

  const isExternal = external ?? false;
  const platformName = external_platform ?? '';

  const activeProps = {
    component: Link,
    to: isExternal ? externalLink : id,
    style: { cursor: 'pointer' },
    target: isExternal ? '_blank' : undefined,
    rel: isExternal ? 'noopener noreferrer' : undefined,
  };

  const inactiveProps = {
    style: { cursor: 'not-allowed' },
  };

  const tooltipText =
    isExternal && !isDisabled ? `This will redirect to our partner ${platformName} platform` : undefined;

  const CardUI = (
    <Card className="company-card" p="lg" withBorder {...(!isDisabled ? activeProps : inactiveProps)}>
      <Card.Section>
        <Image className="company-card__cover-photo" src={bannerUrl} alt={name} height={200} withPlaceholder />
        <Avatar
          className="company-card__logo"
          src={logoUrl}
          alt={name}
          size="lg"
          mt={-theme.spacing.lg * 2}
          ml={theme.spacing.lg}
        />
      </Card.Section>
      <Box className="company-details">
        <Title order={4} my="sm">
          {name}
        </Title>

        <Text className="company-card__summary" mb="lg" color="gray.7" lineClamp={4}>
          {summary}
        </Text>
      </Box>

      <Box>
        <Text size="sm" my="xs" color="dimmed">
          {location.city}, {location.country}
        </Text>
        <Group spacing="sm">
          <Badge radius="sm" style={{ backgroundColor: statusColorMap[dealStatus], color: 'white' }}>
            {dealStatus}
          </Badge>
          {tags.map((tag) => {
            const tagValue = CompanyTag[tag as keyof typeof CompanyTag] || tag;
            return (
              <Badge key={tag} radius="sm">
                {tagValue}
              </Badge>
            );
          })}
        </Group>
      </Box>
    </Card>
  );

  return tooltipText ? (
    <Tooltip label={tooltipText} withArrow>
      {CardUI}
    </Tooltip>
  ) : (
    CardUI
  );
};

export default CompanyCard;
