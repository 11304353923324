import React from 'react';
import { Stack, Title, List, Alert } from '@mantine/core';
import BankDetailsDisplay from 'components/Common/BankDetailsDisplay';

const WiredInstruction: React.FC<{ commitmentAmount?: string }> = ({ commitmentAmount }) => (
  <Stack>
    <Title order={4} weight={550}>
      {commitmentAmount && `Please transfer $${commitmentAmount}`}
    </Title>
    <Alert color="orange" title="Wire Transfer Instructions">
      <List style={{ fontSize: 13 }}>
        <List.Item>
          <b>Account Holder Name:</b> Ensure the account holder&apos;s name matches the signatory name. Transfers from
          accounts with different names will not be accepted and will be returned.
        </List.Item>
        <List.Item>
          <b>Banking Fees and Charges:</b> All banking fees, charges, and forex spreads must be covered under the{' '}
          <b>OUR</b> instruction. This ensures the full transfer amount is credited to our account without deductions.
        </List.Item>
        <List.Item>
          <b>Currency:</b> Remit funds exclusively in <b>USD</b>. Transfers in other currencies may be rejected.
        </List.Item>
        <List.Item>
          <b>Purpose Code:</b> If required, use the purpose code <b>P0093</b>.
        </List.Item>
        <List.Item>
          <b>Timing:</b> Transfer funds before the deal closure to avoid cancellation. Late payments may result in
          missed deadlines.
        </List.Item>
        <List.Item>
          <b>Returns and Delays:</b> Returned wires may cause significant delays. Additionally, we are not liable for
          any foreign exchange or transaction charges incurred if funds are wired from incorrect accounts.
        </List.Item>
      </List>
    </Alert>
    <BankDetailsDisplay />
  </Stack>
);

WiredInstruction.defaultProps = {
  commitmentAmount: '',
};

export default WiredInstruction;
