/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import { SimpleGrid } from '@mantine/core';
import { useSelector } from 'react-redux';
import { hideNotification, showNotification } from '@mantine/notifications';
import { companyListLoadErrorNotification } from 'pages/homepage/HomepageNotifications';
import { useGetCompanyListQuery } from 'services/company/company.service';
import { ProductAnalytics, PaEvents } from 'analytics';
import CompaniesSkeleton from './CompaniesSkeleton';
import CompanyCard from './CompanyCard';

type Props = any;

const Companies: React.FC<Props> = (props) => {
  const token = useSelector((state: any) => state.auth0.token);
  const skip = !token;
  const { data: companies, error, isLoading } = useGetCompanyListQuery(undefined, { skip });

  useEffect(() => {
    if (error) {
      showNotification(companyListLoadErrorNotification);
    }
    return () => {
      hideNotification(companyListLoadErrorNotification.id as string);
    };
  }, [error]);

  if (error || isLoading || !companies) {
    return <CompaniesSkeleton />;
  }

  const handleCardClick = (deal_id: string, deal_name: string, external?: boolean, external_platform?: string) => {
    ProductAnalytics.trackEvent(PaEvents.DEAL_CARD_CLICK, {
      deal_id,
      deal_name,
      external_platform,
      deal_type: external ? 'external' : 'internal',
    });
  };

  return (
    <SimpleGrid
      cols={6}
      spacing="xl"
      breakpoints={[
        { maxWidth: 1920, cols: 5, spacing: 'xl' },
        { maxWidth: 1600, cols: 4, spacing: 'xl' },
        { maxWidth: 1200, cols: 3, spacing: 'lg' },
        { maxWidth: 992, cols: 2, spacing: 'md' },
        { maxWidth: 768, cols: 1, spacing: 'sm' },
      ]}
      {...props}
    >
      {companies?.items.map((i) => (
        <CompanyCard
          key={i.id}
          id={i.id}
          bannerUrl={i.banner_url}
          location={i.location}
          logoUrl={i.logo_url}
          name={i.company_name}
          summary={i.company_summary}
          tags={i.tags}
          dealEnd={i.end_date}
          dealStart={i.start_date}
          external={i.external}
          externalLink={i.external_link}
          external_platform={i.external_platform}
        />
      ))}
    </SimpleGrid>
  );
};

export default Companies;
