import { companyApi } from 'services/company/company.service';
import { dealsApi } from 'services/deals/deals.service';
import { portfolioApi } from 'services/invest/portfolio.service';
import { entitiesApi } from 'services/invest/entities.service';
import { referralApi } from 'services/referral/referral.service';
import { investApi } from 'services/invest/invest.service';

export default [
  companyApi.middleware,
  dealsApi.middleware,
  portfolioApi.middleware,
  entitiesApi.middleware,
  referralApi.middleware,
  investApi.middleware,
];
